import _ from "lodash";
import "../producto.css";
import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { CAPACIDAD, PESO } from "utility/variables";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import FileUploader from "../../Utils/FileUploader/FileUploader";
import FraccionesComponent from "../Fraccion/FraccionContainer";
import { change } from 'redux-form';

import {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderPorcentaje,
    renderSearchSelect
} from "Utils/renderField/renderField";
import { RenderCurrency as RCurrency } from "Utils/renderField/renderReadField";
import Catalogo from "../Crear/Catalogo";
import TreeCategoria from "../TreeCategoria";
import { preciosSucursal } from "../camposProducto";
import Select from 'react-select'

// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};
    if (!values.nombre) errors.nombre = "Campo requerido";

    if (!values.descripcion) errors.descripcion = "Campo requerido";

    if (values.tipo_producto != "100") {
        if (!values.precio && values.precio != "0")
            errors.precio = "Campo requerido";
        if (values.codigo_barras) {
            // if(values.codigo_barras.length > 20)
            //     errors.codigo_barras = 'Debe tener 20 caracteres o menos.';
            if (!/^\w+$/i.test(values.codigo_barras))
                errors.codigo_barras = "Debe tener solo números y/o letras.";
        }
        if (values.descripcion_codigo_barras) {
            if (values.descripcion_codigo_barras.length > 20)
                errors.descripcion_codigo_barras =
                    "Debe tener 20 caracteres o menos.";
        }
    } else {
        values.precio = null;
        values.precio2 = null;
        values.precio3 = null;
        values.precio4 = null;
        values.precio5 = null;
        values.codigo_barras = null;
        values.descripcion_codigo_barras = null;
    }
    if (!values.inventariable) {
        values.alertar = false;
        values.minimo_existencias = null;
        values.tiene_fracciones = false;
    }
    if (values.alertar) {
        if (!values.minimo_existencias)
            errors.minimo_existencias = "Campo requerido";
    } else {
        values.minimo_existencias = null;
    }

    if (values.tienda_online) {
        if (!values.precio_online) {
            errors.precio_online = "Campo requerido";
        }
    }

    if (values.precios_sucursal) {
        if (values.precios_sucursal.length) {
            const preciosSucursalArrayErrors = [];
            values.precios_sucursal.forEach((precio_sucursal, index) => {
                const precioSucursalErrors = {};
                if (
                    !precio_sucursal["precio"] &&
                    precio_sucursal["precio"] != "0"
                )
                    precioSucursalErrors.precio = "Campo requerido";
                preciosSucursalArrayErrors[index] = precioSucursalErrors;
            });

            if (preciosSucursalArrayErrors.length)
                errors.precios_sucursal = preciosSucursalArrayErrors;
        }
    }

    return errors;
};

const Form = (props) => {
    // state
    const {
        unidades_de_medida_empresa,
        tipoProductoValue,
        fraccionadoValue,
        inventariableValue,
        alertaValue,
        unidadDeMedidaValue,
        seleccion_unidades_medida,
        foto_producto,
        conf_tienda_online,
        tienda_onlineValue,
        nombres_precios,
        conf_porcentaje_precio,
        getFamilias,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
    } = props;
    const {
        categorias,
        categorias_seleccionados,
        categoriaSelect,
        categorias_expanded,
        sucursales,
        sucursal,
        sucursal_activo,
    } = props;
    // bind
    const {
        handleSubmit,
        editarProducto,
        onFileChange,
        producto,
        cambioSeleccionUnidad,
        cambioTipoProducto,
        loader,
        asignarCategoria,
        setCategoriasExpanded,
        changeSucursal,
        changeSucursalActivo,
        cambioPorcentajePrecio,
        kilataje,
        dispatch,
        conf_esjoyeria
    } = props;

    //  unidades de medida por el tipo
    let unidades_medida = [];
    // let unidades_de_medida_seleccionados= [];
    //  peso
    if (tipoProductoValue == "1000") {
        unidades_medida = _.filter(
            unidades_de_medida_empresa,
            function (unidad) {
                return unidad.tipo == PESO;
            }
        );
    } else if (tipoProductoValue == "2000") {
        unidades_medida = _.filter(
            unidades_de_medida_empresa,
            function (unidad) {
                return unidad.tipo == CAPACIDAD;
            }
        );
    }
    unidades_medida = _.orderBy(unidades_medida, ["capacidad"], ["asc"]);
    // unidades_medida.forEach((unidad) => {
    //     const esta = _.find(unidades_medidas_seleccionadas, function(id_unidad) { return id_unidad == unidad.id})
    //     if (esta) {
    //         unidad.seleccionado = true
    //         unidades_de_medida_seleccionados.push(unidad)
    //     } else {
    //         unidad.seleccionado = false
    //     }

    // })

    const tiene_imagen = producto.imagen ? true : foto_producto ? true : false;

    const kilatajes = [
    {
        label: '10k',
        value: 10
    },
     {
        label: '14k',
        value: 14
    },
     {
        label: '18k',
        value: 18
    },
     {
        label: '21k',
        value: 21
    },
     {
        label: '24k',
        value: 24
    }
];

    return (
        <div className="grid-container mt">
            <LoadMask loading={loader} dark blur>
                <div className="grid-titulo padding-15">
                    Edición de producto
                </div>
                <div className="grid-cuerpo padding-15  p-sm-0 pt-sm-1 pb-sm-1 mt">
                    <form>
                        <div>
                            <div className="row">
                                <div className="col-sm-6 content-drop-zone">
                                    <div className="row">
                                        <div className="col-12">
                                            <FileUploader
                                                onFileChange={onFileChange}
                                                icono={"add-img"}
                                                frase={"producto."}
                                                clave={"foto_producto"}
                                                width="100%"
                                                source={
                                                    producto.imagen
                                                        ? producto.imagen
                                                        : null
                                                }
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-2 my-2 text-center">
                                                Fotos adicionales
                                            </div>
                                            <Catalogo
                                                disabled={
                                                    tiene_imagen ? "" : true
                                                }
                                                onFileChange={onFileChange}
                                                catalogo={producto}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row-fields">
                                        <div className="form-group">
                                            <label htmlFor="nombre">
                                                Nombre *
                                            </label>
                                            <Field
                                                name="nombre"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="marca">Marca</label>
                                            <Field
                                                name="marca"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                        { /* familias */}
                                        <div className="form-group">
                                        <label htmlFor="familia">Familia</label>
                                            <Field
                                                name="familia"
                                                component={renderSearchSelect}
                                                loadOptions={getFamilias}  
                                                valueKey="id"
                                                labelKey="nombre"
                                                placeholder="Selecciona una familia"
                                            />
                                         </div>
                                
                                    </div>
                                    {Boolean(!fraccionadoValue) ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="codigo_barras">
                                                    Código de barras
                                                </label>
                                                <Field
                                                    name="codigo_barras"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {Boolean(!fraccionadoValue) ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="descripcion_codigo_barras">
                                                    Descripción código de barras
                                                </label>
                                                <Field
                                                    name="descripcion_codigo_barras"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {Boolean(!fraccionadoValue) &&
                                    conf_tienda_online ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="tienda_online">
                                                    Publicar en tienda online
                                                </label>
                                                <br />
                                                <label className="switch">
                                                    <Field
                                                        name="tienda_online"
                                                        component="input"
                                                        type="checkbox"
                                                        
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {conf_esjoyeria && 
                            
                            <div className="d-flex col-12 p-0">
                                    <div className="form-group col-sm-12 col-md-2 col-lg-2">
                                        <label htmlFor="kilataje">
                                            Kilataje
                                        </label>
                                        <Select
                                            className="form-control"
                                            options={kilatajes}
                                            name="kilataje"
                                            label={"value"}
                                            value={
                                                {
                                                    label:kilataje + "k",
                                                    value:kilataje
                                                }
                                            }
                                            onChange={(selectedOption) => {
                                                setTimeout(() => {
                                                    dispatch(change('ProductoEditar', 'kilataje', selectedOption.value))
                                                })
                                            }}
                                        />

                                    </div>
                                    <div className="form-group col-sm-12 col-md-2 col-lg-2">
                                        <label htmlFor="peso">Peso (Gramos)</label>
                                        <Field
                                            name="peso"
                                            component={renderField}
                                            type="number"
                                            className="form-control"
                                            />
                                    </div>
                                </div>
                            }
                                    
                            {Boolean(!fraccionadoValue) ? (
                                <div className="row row-fields">
                                    <div className="col-12 form-group mb-1 mt-3">
                                        <label htmlFor="precio">
                                            Precios generales
                                        </label>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                        {nombres_precios ? (
                                            <label htmlFor="precio">
                                                {nombres_precios.nombre_precio}{" "}
                                                *
                                            </label>
                                        ) : (
                                            <label htmlFor="precio">
                                                Precio *
                                            </label>
                                        )}
                                        <Field
                                            name="precio"
                                            component={renderCurrency}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio2",
                                                        "ProductoEditar"
                                                    );
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio3",
                                                        "ProductoEditar"
                                                    );
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio4",
                                                        "ProductoEditar"
                                                    );
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio5",
                                                        "ProductoEditar"
                                                    );
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                        {nombres_precios ? (
                                            <label htmlFor="precio2">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                {nombres_precios.nombre_precio2}
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio2Value
                                                                    ? parseFloat(
                                                                          precio2Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        ) : (
                                            <label htmlFor="precio2">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                Precio 2
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio2Value
                                                                    ? parseFloat(
                                                                          precio2Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        )}
                                        {conf_porcentaje_precio ? (
                                            <Field
                                                name="porcent_precio2"
                                                component={renderPorcentaje}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "porcent_precio2",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Field
                                                name="precio2"
                                                component={renderCurrency}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "precio2",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                        {nombres_precios ? (
                                            <label htmlFor="precio3">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                {nombres_precios.nombre_precio3}
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio3Value
                                                                    ? parseFloat(
                                                                          precio3Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        ) : (
                                            <label htmlFor="precio3">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                Precio 3
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio3Value
                                                                    ? parseFloat(
                                                                          precio3Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        )}
                                        {conf_porcentaje_precio ? (
                                            <Field
                                                name="porcent_precio3"
                                                component={renderPorcentaje}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "porcent_precio3",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Field
                                                name="precio3"
                                                component={renderCurrency}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "precio3",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                        {nombres_precios ? (
                                            <label htmlFor="precio4">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                {nombres_precios.nombre_precio4}
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio4Value
                                                                    ? parseFloat(
                                                                          precio4Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        ) : (
                                            <label htmlFor="precio4">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                Precio 4
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio4Value
                                                                    ? parseFloat(
                                                                          precio4Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        )}
                                        {conf_porcentaje_precio ? (
                                            <Field
                                                name="porcent_precio4"
                                                component={renderPorcentaje}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "porcent_precio4",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Field
                                                name="precio4"
                                                component={renderCurrency}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "precio4",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                        {nombres_precios ? (
                                            <label htmlFor="precio5">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                {nombres_precios.nombre_precio5}
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio5Value
                                                                    ? parseFloat(
                                                                          precio5Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        ) : (
                                            <label htmlFor="precio5">
                                                {conf_porcentaje_precio && (
                                                    <span className="mr-2">
                                                        %
                                                    </span>
                                                )}
                                                Precio 5
                                                {conf_porcentaje_precio && (
                                                    <span className="ml-2 negro-dark2">
                                                        (
                                                        <RCurrency
                                                            value={
                                                                precio5Value
                                                                    ? parseFloat(
                                                                          precio5Value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        )
                                                    </span>
                                                )}
                                            </label>
                                        )}
                                        {conf_porcentaje_precio ? (
                                            <Field
                                                name="porcent_precio5"
                                                component={renderPorcentaje}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "porcent_precio5",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Field
                                                name="precio5"
                                                component={renderCurrency}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setTimeout(() => {
                                                        cambioPorcentajePrecio(
                                                            "precio5",
                                                            "ProductoEditar"
                                                        );
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                    {tienda_onlineValue && (
                                        <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                            <label htmlFor="precio_online">
                                                Precio tienda online *
                                            </label>
                                            <Field
                                                name="precio_online"
                                                component={renderCurrency}
                                                decimalScale={2}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : null}

                            {/* ASIGNACION DE PRECIOS POR SUCURSAL */}
                            {Boolean(!fraccionadoValue) && (
                                <div className="row row-fields">
                                    <div className="col-12 col-md-12 col-lg-12 form-group">
                                        <label>Precios por sucursal</label>
                                        <FieldArray
                                            name="precios_sucursal"
                                            component={preciosSucursal}
                                            sucursales={sucursales}
                                            sucursal={sucursal}
                                            changeSucursal={changeSucursal}
                                            sucursal_activo={sucursal_activo}
                                            nombres_precios={nombres_precios}
                                            changeSucursalActivo={
                                                changeSucursalActivo
                                            }
                                            conf_porcentaje_precio={
                                                conf_porcentaje_precio
                                            }
                                            cambioPorcentajePrecio={
                                                cambioPorcentajePrecio
                                            }
                                            precios_sucursalValue={
                                                precios_sucursalValue
                                            }
                                            nombreFormulario="ProductoEditar"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="row row-fields">
                                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                    <label htmlFor="vendible">Vendible</label>
                                    <br />
                                    <label className="switch">
                                        <Field
                                            name="vendible"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                    <label htmlFor="inventariable">
                                        Inventariable
                                    </label>
                                    <br />
                                    <label className="switch">
                                        <Field
                                            name="inventariable"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {Boolean(
                                    inventariableValue && !fraccionadoValue
                                ) ? (
                                    <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                        <label htmlFor="alertar">Alertar</label>
                                        <br />
                                        <label className="switch">
                                            <Field
                                                name="alertar"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                ) : null}
                                {Boolean(alertaValue) &&
                                Boolean(
                                    inventariableValue && !fraccionadoValue
                                ) ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <label htmlFor="minimo_existencias">
                                            Mínimo existencia *
                                        </label>
                                        <Field
                                            name="minimo_existencias"
                                            component={renderNumber}
                                            type="number"
                                            className="form-control"
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className="row row-fields">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <label htmlFor="descripcion">
                                        Descripción *
                                    </label>
                                    <Field
                                        name="descripcion"
                                        component={renderTextArea}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {Boolean(inventariableValue) &&
                            !tienda_onlineValue ? (
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <label htmlFor="tipo_producto">
                                            Este producto se va a maneja como
                                        </label>
                                        <br />
                                        <div className="d-flex flex-column flex-lg-row ml-3 mt-3">
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="1"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "1",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Simple &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="100"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "100",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Fraccionado &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="1000"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "1000",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Peso &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="2000"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "2000",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Capacidad
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {tienda_onlineValue && conf_tienda_online && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="categorias">
                                                Seleccione la categoría
                                            </label>
                                            <br />
                                            <small className="fnt-12">
                                                Puede seleccionar hasta un
                                                máximo de 3 categorías
                                            </small>
                                        </div>
                                        <TreeCategoria
                                            categorias={categorias}
                                            asignarCategoria={asignarCategoria}
                                            setCategoriasExpanded={
                                                setCategoriasExpanded
                                            }
                                            categorias_seleccionados={
                                                categorias_seleccionados
                                            }
                                            categorias_expanded={
                                                categorias_expanded
                                            }
                                        />
                                        {categoriaSelect && (
                                            <div className="invalid-feedback d-block my-2 fnt-10 ml-2">
                                                Campo requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                    {/* SECCION DE ILUSTRACION DE TIPO DE PRODUCTO */}
                    <div className="d-flex flex-column">
                        {Boolean(inventariableValue) && !tienda_onlineValue && (
                            <div className="w-100">
                                {/* PRODUCTO SIMPLE */}
                                {tipoProductoValue == "1" && (
                                    <div className="d-flex flex-column text-center justify-content-center align-items-center">
                                        <h5 className="font-weight-normal negro-dark2 mt-3 mb-5 fnt-08-rem">
                                            No aplica ningún tratamiento
                                            especial
                                        </h5>
                                        <img
                                            src={icons.producto_simple}
                                            alt="producto_simple"
                                            className="img-fluid"
                                            style={{ maxWidth: "200px" }}
                                        />
                                    </div>
                                )}
                                {/* PRODUCTO FRACCIONADO */}
                                {tipoProductoValue == "100" && (
                                    <div className="d-flex flex-column text-center justify-content-center align-items-center">
                                        <h5 className="font-weight-normal negro-dark2 mt-3 fnt-08-rem">
                                            Esta opción te permite trabajar por
                                            fracciones, por ejemplo: Las
                                            farmacias que venden sus productos
                                            individuales.
                                        </h5>
                                        <h5 className="font-weight-normal negro-dark2 mb-5 fnt-08-rem">
                                            Observa la siguiente imagen
                                        </h5>
                                        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                <h5 className="negro-dark font-weight-bold">
                                                    Fardo
                                                </h5>
                                                <img
                                                    src={icons.fraccion_fardo}
                                                    alt="producto_simple"
                                                    className="img-fluid"
                                                    style={{
                                                        maxWidth: "150px",
                                                    }}
                                                />
                                            </div>
                                            <img
                                                className="rotar-90-deg"
                                                src={icons.flecha_derecha}
                                                alt="flecha-derecha"
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                }}
                                            />
                                            <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                <h5 className="negro-dark font-weight-bold">
                                                    Caja
                                                </h5>
                                                <img
                                                    src={icons.fraccion_caja}
                                                    alt="producto_simple"
                                                    className="img-fluid"
                                                    style={{
                                                        maxWidth: "150px",
                                                    }}
                                                />
                                            </div>
                                            <img
                                                className="rotar-90-deg"
                                                src={icons.flecha_derecha}
                                                alt="flecha-derecha"
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                }}
                                            />
                                            <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                <h5 className="negro-dark font-weight-bold">
                                                    Blister
                                                </h5>
                                                <img
                                                    src={icons.fraccion_blister}
                                                    alt="producto_simple"
                                                    className="img-fluid"
                                                    style={{
                                                        maxWidth: "150px",
                                                    }}
                                                />
                                            </div>
                                            <img
                                                className="rotar-90-deg"
                                                src={icons.flecha_derecha}
                                                alt="flecha-derecha"
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                }}
                                            />
                                            <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                <h5 className="negro-dark font-weight-bold">
                                                    Pastillas
                                                </h5>
                                                <img
                                                    src={
                                                        icons.fraccion_pastilla
                                                    }
                                                    alt="producto_simple"
                                                    className="img-fluid"
                                                    style={{
                                                        maxWidth: "150px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* PRODUCTO PESO */}
                                {tipoProductoValue == "1000" && (
                                    <div className="d-flex flex-column text-center justify-content-center align-items-center">
                                        {!seleccion_unidades_medida ? (
                                            <div className="w-100">
                                                <h5 className="font-weight-normal negro-dark2 mt-3 fnt-08-rem">
                                                    Esta opción te permite
                                                    comprar y vender en medidas
                                                    de peso, por ejemplo:
                                                    comprar 300 libras y vender
                                                    15.38 libras.
                                                </h5>
                                                <h5 className="font-weight-normal negro-dark2 mb-5 fnt-08-rem">
                                                    Observa la siguiente imagen
                                                </h5>
                                                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                        <h5 className="negro-dark2 font-weight-bold">
                                                            Quintal
                                                        </h5>
                                                        <img
                                                            src={
                                                                icons.producto_peso_saco
                                                            }
                                                            alt="producto_simple"
                                                            className="img-fluid"
                                                            style={{
                                                                maxWidth:
                                                                    "150px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="linea-vertical rotar-90-deg"></div>
                                                    {/* <img className="rotar-90-deg" src={icons.flecha_derecha_naranja} alt="flecha-derecha" style={{width:"50px", height:"50px"}}/> */}
                                                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                        <h5 className="negro-dark2 font-weight-bold">
                                                            Libra
                                                        </h5>
                                                        <img
                                                            src={
                                                                icons.producto_peso_libra
                                                            }
                                                            alt="producto_simple"
                                                            className="img-fluid"
                                                            style={{
                                                                maxWidth:
                                                                    "150px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-100 mb-4">
                                                <h5 className="font-weight-normal negro-dark2 mt-3 mb-5 fnt-08-rem">
                                                    Elige las medidas que deseas
                                                    comprar y vender tu producto
                                                </h5>
                                                <div className="ml30 mr30 d-flex flex-column flex-lg-row justify-content-center align-items-start align-items-lg-center">
                                                    {unidades_medida.map(
                                                        (unidad, index) => {
                                                            return (
                                                                <div
                                                                    className="radio c-radio c-radio-nofont d-flex"
                                                                    key={index}
                                                                >
                                                                    <label className="negro-dark2 font-weight-normal">
                                                                        <Field
                                                                            name="unidad_de_medida"
                                                                            component="input"
                                                                            type="radio"
                                                                            checked={
                                                                                unidad.id ==
                                                                                unidadDeMedidaValue
                                                                            }
                                                                            value={
                                                                                unidad.id
                                                                            }
                                                                        />
                                                                        <span />
                                                                        {
                                                                            unidad.nombre
                                                                        }
                                                                        &nbsp;&nbsp;
                                                                    </label>
                                                                </div>
                                                                // <div className="checkbox c-checkbox" key={index}>
                                                                //     <label className="needsclick">
                                                                //         <input type="checkbox" checked={unidad.seleccionado} onChange={(e) => {
                                                                //             changeUnidadesMedidas(!unidad.seleccionado, unidad.id);
                                                                //         }} />
                                                                //         <span className="fa fa-check" />
                                                                //         {unidad.nombre}&nbsp;&nbsp;
                                                                //     </label>
                                                                // </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {!seleccion_unidades_medida && (
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-4 mb-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary-outline fnt-md-16 fnt-sm-12"
                                                    onClick={() => {
                                                        cambioSeleccionUnidad(
                                                            !seleccion_unidades_medida
                                                        );
                                                    }}
                                                >
                                                    <em className="fa fa-check"></em>
                                                    <span>
                                                        {" "}
                                                        Seleccionar unidades de
                                                        medidas
                                                    </span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* PRODUCTO CAPACIDAD */}
                                {tipoProductoValue == "2000" && (
                                    <div className="d-flex flex-column text-center justify-content-center align-items-center">
                                        {!seleccion_unidades_medida ? (
                                            <div className="w-100">
                                                <h5 className="font-weight-normal negro-dark2 mt-3 fnt-08-rem">
                                                    Esta opción te permite
                                                    comprar y vender en medidas
                                                    de capacidad, por ejemplo:
                                                    comprar 300 litros y vender
                                                    10.35 litros.
                                                </h5>
                                                <h5 className="font-weight-normal negro-dark2 mb-5 fnt-08-rem">
                                                    Observa la siguiente imagen
                                                </h5>
                                                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                        <h5 className="negro-dark font-weight-bold">
                                                            Galón
                                                        </h5>
                                                        <img
                                                            src={
                                                                icons.producto_capacidad_galon
                                                            }
                                                            alt="producto_simple"
                                                            className="img-fluid"
                                                            style={{
                                                                maxWidth:
                                                                    "150px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="linea-vertical rotar-90-deg"></div>
                                                    {/* <img className="rotar-90-deg" src={icons.flecha_derecha_naranja} alt="flecha-derecha" style={{width:"50px", height:"50px"}}/> */}
                                                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                        <h5 className="negro-dark font-weight-bold">
                                                            Litro
                                                        </h5>
                                                        <img
                                                            src={
                                                                icons.producto_capacidad_litro
                                                            }
                                                            alt="producto_simple"
                                                            className="img-fluid"
                                                            style={{
                                                                maxWidth:
                                                                    "150px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="linea-vertical rotar-90-deg"></div>
                                                    {/* <img className="rotar-90-deg" src={icons.flecha_derecha_naranja} alt="flecha-derecha" style={{width:"50px", height:"50px"}}/> */}
                                                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                                                        <h5 className="negro-dark font-weight-bold">
                                                            Mililitros
                                                        </h5>
                                                        <img
                                                            src={
                                                                icons.producto_capacidad_mililitro
                                                            }
                                                            alt="producto_simple"
                                                            className="img-fluid"
                                                            style={{
                                                                maxWidth:
                                                                    "150px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-100 mb-4">
                                                <h5 className="font-weight-normal negro-dark2 mt-3 mb-5 fnt-08-rem">
                                                    Elige las medidas que deseas
                                                    comprar y vender tu producto
                                                </h5>
                                                <div className="ml30 mr30 d-flex flex-column flex-lg-row justify-content-center align-items-start align-items-lg-center">
                                                    {unidades_medida.map(
                                                        (unidad, index) => {
                                                            return (
                                                                <div
                                                                    className="radio c-radio c-radio-nofont d-flex"
                                                                    key={index}
                                                                >
                                                                    <label className="negro-dark2 font-weight-normal">
                                                                        <Field
                                                                            name="unidad_de_medida"
                                                                            component="input"
                                                                            type="radio"
                                                                            checked={
                                                                                unidad.id ==
                                                                                unidadDeMedidaValue
                                                                            }
                                                                            value={
                                                                                unidad.id
                                                                            }
                                                                        />
                                                                        <span />
                                                                        {
                                                                            unidad.nombre
                                                                        }
                                                                        &nbsp;&nbsp;
                                                                    </label>
                                                                </div>
                                                                // <div className="checkbox c-checkbox" key={index}>
                                                                //     <label className="needsclick">
                                                                //         <input type="checkbox" checked={unidad.seleccionado} onChange={(e) => {
                                                                //             changeUnidadesMedidas(!unidad.seleccionado, unidad.id);
                                                                //         }} />
                                                                //         <span className="fa fa-check" />
                                                                //         {unidad.nombre}&nbsp;&nbsp;
                                                                //     </label>
                                                                // </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {!seleccion_unidades_medida && (
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-4 mb-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary-outline fnt-md-16 fnt-sm-12"
                                                    onClick={() => {
                                                        cambioSeleccionUnidad(
                                                            !seleccion_unidades_medida
                                                        );
                                                    }}
                                                >
                                                    <em className="fa fa-check"></em>
                                                    <span>
                                                        {" "}
                                                        Seleccionar unidades de
                                                        medidas
                                                    </span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* SECCION DE CRUD DE FRACCIONES */}
                    {Boolean(fraccionadoValue) &&
                    Boolean(inventariableValue) ? (
                        <div className="row row-fields">
                            <FraccionesComponent />
                        </div>
                    ) : null}
                </div>
                {/* FOOTER */}
                <div className="d-flex justify-content-center flex-row footer-container">
                    <Link
                        className="btn btn-tertiary m-1 align-self-center"
                        to="/productos"
                    >
                        <img src={icons.cancelar} alt="" />
                        Cancelar
                    </Link>
                    <button
                        type="button"
                        className="btn btn-primary m-1 align-self-center"
                        onClick={handleSubmit((values) => {
                            // validacionCustom(values, crearProducto);
                            editarProducto();
                        })}
                    >
                        <img src={icons.save} alt="" />
                        Guardar
                    </button>
                </div>
            </LoadMask>
        </div>
    );
};

let ProductoForm = reduxForm({
    // a unique name for the form
    form: "ProductoEditar",
    validate,
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("ProductoEditar");

ProductoForm = connect((state) => {
    const store = state.producto;
    const form = state.form.ProductoEditar;
    let categoriaSelect = false;
    const tienda_onlineValue = selector(state, "tienda_online");
    if (form && tienda_onlineValue) {
        const { submitFailed, submitSucceeded } = form;
        categoriaSelect =
            store.categorias_seleccionados.length <= 0 &&
            (submitFailed || submitSucceeded);
    }

    const tipoProductoValue = selector(state, "tipo_producto");
    const fraccionadoValue = parseInt(tipoProductoValue) == 100 ? true : false;
    const inventariableValue = selector(state, "inventariable");
    const alertaValue = selector(state, "alertar");
    const unidadDeMedidaValue = selector(state, "unidad_de_medida");
    const kilataje = selector(state, "kilataje");

    //  PRECIOS
    const precio2Value = selector(state, "precio2");
    const precio3Value = selector(state, "precio3");
    const precio4Value = selector(state, "precio4");
    const precio5Value = selector(state, "precio5");
    const precios_sucursalValue = selector(state, "precios_sucursal");
    // FAMILIA
    const familia = selector(state, 'familia');
    return {
        familia,
        tipoProductoValue,
        fraccionadoValue,
        inventariableValue,
        alertaValue,
        unidadDeMedidaValue,
        tienda_onlineValue,
        categoriaSelect,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
        kilataje,

    };
})(ProductoForm);

export default ProductoForm;
