import React from "react";
import Select, { AsyncCreatable, Async } from "react-select";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Switch from "react-switch";
import _ from "lodash";
import DatePicker from "Utils/DatePicker";
import { icons } from "icons";
import { RenderCurrency } from "./renderFieldsCurrency";
import "react-select/dist/react-select.css";
import { useVerificarCui, useValidateNit} from '../../Utils/hooks/auth_fel';

export const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    disabled,
    className,
    autoFocus,
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <input
                {...input}
                placeholder={label}
                type={type}
                disabled={disabled}
                className={classNames(
                    !!className ? className : "form-control",
                    { "is-invalid": invalid }
                )}
                autoFocus={autoFocus ? autoFocus : false}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};


export const renderFieldDPI = ({
    input,
    label,
    onBlurs,
    autofocus,
    letras,
    onKeyDownEnter,
    inputId,
    disabled,
    addClass,
    utiliza_fel,
    id_empresa,
    type, meta: { touched, error }
    }) => {
    const invalid = touched && error;
    const { loading, errorFetch, data, setCui } = useVerificarCui(id_empresa);
    // const [text, setText] = React.useState("");
    React.useEffect(() => {
        if (data) {
            onKeyDownEnter(data);
        }
    }, [data]);
    return (
        <div>
            <input
                {...input}
                placeholder={label}
                type={type}
                autoFocus={autofocus ? true:false}
                id={inputId}
                onBlur={(value) => { if (onBlurs && utiliza_fel) setCui(value.target.value); }} //DESCOMENTAR
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && onKeyDownEnter && utiliza_fel) {
                        e.preventDefault();
                        if (invalid){
                            setCui(input.value);
                        }
                    }
                }}
                onKeyPress={letras ? (event)=>{if(!/[a-zA-Z]|\s|ñ/i.test(event.key))event.preventDefault()}: ""}
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
};



export const renderTextArea = ({
    input,
    label,
    rows,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <textarea
                {...input}
                placeholder={label}
                style={{ resize: "none" }}
                rows={rows ? rows : 3}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};
export const renderSearchSelect = ({
    input,
    disabled,
    loadOptions,
    valueKey,
    labelKey,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                disabled={disabled}
                value={input.value}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(!!e ? e[valueKey] : null);
                }}
                placeholder="Seleccionar"
                searchPromptText="Escriba para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                    {input.value}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderStaticSelect = ({
    input,
    disabled,
    options = [],  // Aseguramos que options sea un array por defecto
    valueKey,
    labelKey,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    // Solo hacemos map si options es efectivamente un array
    const formattedOptions = Array.isArray(options)
        ? options.map(option => ({
            value: option[valueKey],
            label: option[labelKey],
        }))
        : [];

    // Encontrar el valor seleccionado basado en el valueKey
    const selectedOption = formattedOptions.find(
        option => String(option.value) === String(input.value)
    );

    return (
        <React.Fragment>
            <Select
                isDisabled={disabled}
                value={selectedOption || null}
                options={formattedOptions}
                className={classNames("form-control", { "is-invalid": invalid })}
                onChange={(selected) => {
                    input.onChange(selected ? selected.value : null);
                }}
                placeholder="Seleccionar"
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};


export const renderSearchCreateSelect = ({
    input,
    disabled,
    loadOptions,
    valueKey,
    labelKey,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncCreatable
                disabled={disabled}
                value={input.value}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(!!e ? e[valueKey] : null);
                }}
                searchPromptText="Escriba para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
                promptTextCreator={(label) => {
                    return `Crear opción ${label}`;
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                    {input.value}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderSelectField = ({
    input,
    disabled,
    options,
    item_vacio,
    hidden_selected,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <select
                {...input}
                disabled={disabled}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            >
                {item_vacio && hidden_selected ?  ( <option hidden selected value="">Seleccionar...</option> ) : null}
                {item_vacio && !hidden_selected ?  ( <option value="" >Seleccionar...</option> ) : null}

                {options.map((opcion) => {
                    return (
                        <option
                            key={
                                typeof opcion === "string" ? opcion : opcion.id
                            }
                            value={
                                typeof opcion === "string"
                                    ? opcion
                                    : opcion.value
                            }
                        >
                            {typeof opcion === "string" ? opcion : opcion.label}
                        </option>
                    );
                })}
            </select>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderNumber = ({
    input,
    label,
    type,
    decimalScale,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale ? decimalScale : 0}
                fixedDecimalScale={true}
                value={input.value}
                thousandSeparator={true}
                prefix={""}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderPorcentaje = ({
    input,
    label,
    type,
    decimalScale,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <NumberFormat
                className={classNames("form-control text-right", {
                    "is-invalid": invalid,
                })}
                placeholder="0 %"
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale={true}
                value={input.value}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                    console.log("VALOR DESDE EL CAMPO");
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderNumberConControles = (props) => {
    const { input } = props;
    const { minValue, maxValue, splitValue, onCambio } = props;
    return (
        <div
            className="d-flex flex-row"
            style={{ height: "25px", width: "90px" }}
        >
            <button
                className="btn btn-azul-venta btn-menos d-flex align-items-center justify-content-center"
                onClick={(e) => {
                    e.stopPropagation();
                    const actualValue = !isNaN(input.value)
                        ? parseFloat(input.value)
                        : 0;
                    let split = splitValue || 1;
                    if (
                        minValue !== undefined &&
                        minValue !== null &&
                        actualValue - split < minValue
                    ) {
                        split = 0;
                    }
                    const value = actualValue - split;
                    input.onChange(value);
                    !!onCambio && onCambio(value);
                }}
                type="button"
            >
                <img
                    className="img-icono-cantidades"
                    src={icons.menos}
                    title="Disminuir"
                    alt="-"
                />
            </button>
            <NumberFormat
                className="form-control venta-cantidad text-center h-100 px-0"
                decimalScale={0}
                fixedDecimalScale={true}
                value={input.value}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onValueChange={(values) => {
                    let newValue = parseFloat(values.value) || 0;
                    if (
                        minValue !== undefined &&
                        minValue !== null &&
                        newValue < minValue
                    ) {
                        newValue = minValue;
                    }
                    if (
                        maxValue !== undefined &&
                        maxValue !== null &&
                        newValue > maxValue
                    ) {
                        newValue = maxValue;
                    }
                    input.onChange(newValue);
                    !!onCambio && onCambio(newValue);
                }}
                {...input}
            />
            <button
                className="btn btn-azul-venta btn-mas d-flex align-items-center justify-content-center"
                onClick={(e) => {
                    e.stopPropagation();
                    const actualValue = !isNaN(input.value)
                        ? parseFloat(input.value)
                        : 0;
                    let split = splitValue || 1;
                    if (
                        maxValue !== undefined &&
                        maxValue !== null &&
                        actualValue + split > maxValue
                    ) {
                        split = 0;
                    }
                    const value = actualValue + split;
                    input.onChange(value);
                    !!onCambio && onCambio(value);
                }}
                type="button"
            >
                <img
                    className="img-icono-cantidades"
                    src={icons.mas}
                    title="Aumentar"
                    alt="+"
                />
            </button>
        </div>
    );
};

export const renderCurrency = ({
    className,
    input,
    disabled,
    label,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <RenderCurrency
            disabled={disabled}
            className={className}
            input={input}
            invalid={invalid}
            error={error}
        />
        // <React.Fragment>
        //     <NumberFormat className={classNames(className ? className : 'form-control', { 'is-invalid': invalid })}
        //         decimalScale={2} fixedDecimalScale={true}
        //         value={input.value} thousandSeparator={true} prefix={'Q '}
        //         onValueChange={(values) => {
        //             input.onChange(values.value);
        //         }}
        //     />
        //     {invalid && <div className="invalid-feedback">
        //         {error}
        //     </div>}
        // </React.Fragment>
    );
};

export const renderSwitch = ({
    input,
    label,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Switch
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

// TODO testear que sirva como input para form, en especial los css de input invalido
export const renderDatePicker = ({
    input,
    disabled,
    maxDate,
    minDate,
    label,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <DatePicker
                value={input.value}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                onChange={(date) => {
                    input.onChange(date);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderPorcentaje,
    renderFieldDPI,
    renderStaticSelect,
};
